.Switcher-Wrapper {
    color: white !important;
    /*background-color: red;*/
}
.Switcher-Wrapper > .btn {
    border: 1px solid #2f55bd;
    border-radius: 10px;
}
.Switcher-Wrapper > .btn-primary {
    text-align: left;
}

.switch-on.btn {
    padding-left: 10px !important;
    padding-top: 5px;
    background: linear-gradient(270deg, black, #2f55bd);
}

.Switcher-Wrapper-Enabled:hover {
    background: linear-gradient(270deg, black, #4363c2);
    cursor:pointer;
}

.Switcher-Wrapper > .btn-secondary {
    text-align: right;
}

.switch-off.btn {
    padding-right: 10px !important;
    padding-top: 5px;
    background: linear-gradient(90deg, black, #2f55bd);
}

.switch-off.btn:hover {
    background: linear-gradient(90deg, black, #4363c2);
}