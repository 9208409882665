.TransactionList-Wrapper {
    /*border: 1px solid black;*/
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
}

.TransactionList-DayEntry {
    border-radius: 10px;
    margin-bottom: 5px;
    height: 100%;
}
.TransactionList-TransactionEntry {
    color: white;
    display: flex;
    height: 100%;
    margin-bottom: 5px;
}
.TransactionList-Payer {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #2f55bd;
    flex: 1;
    margin-left: 10px;
    display: flex;
    align-items: center;
    max-width: 60px;
}
.TransactionList-Details {
    border: 1px solid black;
    width: 100%;
    display: flex;
    height: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #2f2f2f;
    margin-right: 10px;
    flex: 10
}

.TransactionList-TargetUsers {
    display: flex;
}

.TransactionList-TargetUsers > div{
    padding-left: 0;
    padding-right: 0;
}

.TransactionList-TargetUsersAndDetails {
    background-color: #2f2f2f;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    flex: 10;
}
.TransactionList-TransactionDetails {
    width: 100%;
    display: flex;
}
.TransactionList-TargetDetails {
    margin-left: 20px;
    margin-bottom: 5px;
    margin-right: 5px;
    flex: 10
}

.TransactionList-TransactionAmount {
    width: 100%;
    text-align: right;
    margin-top: 10px;
    margin-right: 10px;
    align-items: center;
    flex: 1;
    padding-bottom: 10px;
}

.TransactionList-TransactionAmount > div {
    width: 100%;
}

.TransactionList-DayHeader {
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: bold;
}

.TransactionList-Header > h1 {
    color: black;
    text-shadow: -0.5px -0.5px 0 white, 0.5px -0.5px 0 white, -0.5px 0.5px 0 white, 0.5px 0.5px 0 white; /* Set the border color and offsets */
}

.TransactionList-Header {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.TransactionList-PaginationHeader {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}