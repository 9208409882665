.AddExpenseGroups-NewGroup {
    background-color: #424242;
    border-radius: 10px;
    padding: 5px;
}


.AddExpenseGroup-Confirm,
.AddExpenseGroup-Name,
.AddExpenseGroup-Users > div {
    padding-top: 5px;
    margin-top: 5px;
}

.AddExpenseGroup-Name > input{
    border-radius: 5px;
    /*border: none;*/
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    width: 100%;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    color: rgb(170, 170, 170);
;
}

.multiselect-container:hover,
.AddExpenseGroup-Name > input:hover {
    background-color: #5e5e5e;
}


.AddExpenseGroup-Confirm > button {
    border-radius: 5px;
    /*border: none;*/
    color: black;
    font-weight: bold;
    background-color: #2f55bd;
    border-style: solid;
    /*border-width: 1px;*/
    width: 100%;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    /*color: rgb(170, 170, 170);*/
    cursor: pointer;
}


.AddExpenseGroup-Confirm > button:hover{
    background-color: #4363c2;
}