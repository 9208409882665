.Button-Wrapper {
    padding-top: 5px;
    margin-top: 5px;
}

.Button-Wrapper > button {
    border-radius: 5px;
    color: black;
    font-weight: bold;
    border-style: solid;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
}

.Button-Disabled > button{
    background-color: #AAAAAAFF;
}

.Button-Scheme-Main > button {
    background-color: #2f55bd;
    cursor: pointer;
}

.Button-Scheme-Main > button:hover{
    background-color: #4363c2;
}

.Button-Scheme-Danger > button {
    background-color: #ff0000;
}

.Button-Scheme-Danger > button:hover{
    background-color: #fc4b4b;
}
