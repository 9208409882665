.DebtMapList-Wrapper {
}

.DebtMapList-Collection {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;

}

.DebtMapList-CollectionHeader {
    background-color: #2f55bd;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-bottom: 5px;
    font-weight: bold;
}

.DebtMapList-CollectionEntries {
    border: 1px solid black;
    padding-bottom: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #2f2f2f;
    color: white;

    padding-left: 20px;
}

.DebtMapList-CollectionEntry {
    display: flex;

}

.DebtMapList-CollectionEntryUser {
}




.DebtMap-Wrapper {
    border: 1px solid black;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
}
.DebtMap-Wrapper >table{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-spacing: 0;
    width: 100%;
}

.DebtMap-Corner {
    background-color: black !important;
}



.DebtMap-Row {
}

.DebtMap-Header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    font-weight: bold;
    background-color: #2f55bd;
}


.DebtMap-SecondHeader {
    background-color: #4363c2;
    font-weight: bold;
}

.DebtMap-Header,
.DebtMap-SecondHeader,
.DebtMap-Data {
    padding: 5px;
    border: 1px solid black;
    margin: 0;
}

.DebtMap-Blank,
.DebtMap-Data {
    color: black;
    background-color: #f2f2f2;
}

.DebtMap-Blank {
    text-align: center;
}