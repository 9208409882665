.UserDisplay-Wrapper {
    padding: 5px;
    height: 40px;
    display: flex;
    white-space: nowrap;
    margin-bottom: 5px;
}

.UserDisplay-MainWrapper{
    border-radius: 20px;
    /*background-color: red;*/
    padding: 5px;
    display: flex;
    height: 40px;
}

.UserDisplay-MainWrapperBackgrounded {
    background-color: rgba(94, 94, 94, 0.4);
}


.UserDisplay-NameWrapper {
    padding-top: 8px;
    float: left;
}

.UserDisplay-ImageWrapper{
    float: left;
    background-size: cover;
    background-position: center center;
    margin-right: 5px;
}

.UserDisplay-ImageSmall {
    height: 30px;
    width: 30px;
    border-radius: 30px;
}

.UserDisplay-ImageLarge {
    height: 40px;
    width: 40px;
    border-radius: 40px;
}

.UserDisplay-Suffix {
    margin-left: 5px;
    padding-top: 13px;
    float: left;
}