.Header-Wrapper {
    width: 100vw;
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    height: 5vh;
    background-color: #2f55bd;
    padding-bottom: 5px;
}

.Header-User,
.Header-Title {
    font-family: 'Aclonica';
}

.Header-Title {
    margin-top: 2vh;
    margin-left: 5%;
    font-size: 150%;
    float: left
}

.Header-User {
    margin-top: 0.5vh;
    font-size: 90%;
    float: right;
}

.Header-Signout {
    margin-top: 0.5vh;
    margin-right: 5%;
    font-size: 90%;
    float: right;
}