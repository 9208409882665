.Login-Wrapper {
    width: 20em;
    height: 100px;

    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;

    margin: auto;
}

.Login-form {
    margin:auto
}

.Login-input-container {
    display: flex;
    justify-content: center;
}

.Login-input-container > input {
    background-color: black;
    border: none;
    border-radius: 4px;
    /*border: 2px white;*/
    box-shadow: 0 0 1px white;
    height: 40px;
    width: 20em;
    margin-bottom: 8px;
    font-family: "Segoe UI";
    padding-left: 10px;
    color: white;
}
.Login-button-container {
    display: flex;
    justify-content: center;
}


.Login-button-container > button {
    width: 20em;
    font-family: "Segoe UI";
    border: none;
    border-radius: 20px;
    height: 30px;
    font-weight: bold;
}