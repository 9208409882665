.ExpandedDetails-TransactionContainer{
    display: flex;
    width: 70vw;
    margin-right: 5vw;
    margin-left: 5vw;
}
.ExpandedDetails-Expenses {
    float: left;
    width: 35vw;
}
.ExpandedDetails-Payments {
    float: left;
    width: 35vw;
}

.ExpandedDetails-MobileTransactions {
    width: 100%;
}

.ExpandedDetails-FullScreenTransactions {
    width: 35vw;
}

.ExpandedDetails-DebtMapContainer{
    display: flex;
    width: 70vw;
    margin-right: 5vw;
    margin-left: 5vw;
}

.ExpandedDetails-DebtMap {
    float: left;
}

.ExpandedDetails-MobileDebt {
    width: 100%
}

.ExpandedDetails-FullScreenDebt {
    width: 35vw;
}
.ExpandedDetails-DebtMap-Header {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.ExpandedDetails-DebtMap-Header > h1{
    float: left;
    color: black;
    text-shadow: -0.5px -0.5px 0 white, 0.5px -0.5px 0 white, -0.5px 0.5px 0 white, 0.5px 0.5px 0 white; /* Set the border color and offsets */
}