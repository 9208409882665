.Pagination-Wrapper {
    min-height: 50px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding-right: 4px;
}

.Pagination-Button {
    margin: 4px;
    float: left;
    height: 2vh;
    min-width: 40px;
    min-height: 30px;
    padding-left: 5px;
}


.Pagination-Button > button {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-color: #2f55bd;
    border-style: solid;
    border-width: 1px;
    border-color: #4363c2;
    color: black;
    font-size: 15px;
}

.Pagination-Button:not(.Pagination-Button-Disabled) > button:hover {
    background-color: #4363c2;
    cursor: pointer;
}


.Pagination-Button-Disabled > button:hover{
    border-color: grey !important;
}
.Pagination-Button-Disabled > button{
    color: white !important;
    background-color: grey !important;
    border-color: grey !important;
}