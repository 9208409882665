.ExpenseGroups-Wrapper {
    margin-top: 7em;
    color: white;
    width: 80vw;
}

.ExpenseGroups-tbody-tr-main {
    color: black;
    /*display: flex;*/
    width: 80vw;
}

:nth-child(2 of .ExpenseGroups-tbody-tr-main) {
    background-color: #2f2f2f;
    color: white
}


.ExpenseGroups-thead-tr-name {
    border-radius: 10px 0px 0px 0px;
}
.ExpenseGroups-thead-tr-expand {
    border-radius: 0px 10px 0px 0px;
}

.ExpenseGroups-tbody-tr-main-last{
    border-radius: 0px 0px 10px 10px;
}


.ExpenseGroups-thead-tr-name,
.ExpenseGroups-tbody-td-name {
    background-color: #2f55bd;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    align-content: center;
    justify-content: center;
    width: 100%;
    display: flex;
    font-size: 30px;
}
.ExpenseGroups-tbody-td-name > h1 {
    color: black;
    text-shadow: -0.5px -0.5px 0 white, 0.5px -0.5px 0 white, -0.5px 0.5px 0 white, 0.5px 0.5px 0 white; /* Set the border color and offsets */
    margin: 0px;
}

.ExpenseGroups-thead-tr-state,
.ExpenseGroups-tbody-td-state {
    width: 16vw;
    float: left;
}

.ExpenseGroups-thead-tr-people,
.ExpenseGroups-tbody-td-people {
    background-color: #2f55bd;
    align-content: center;
    justify-content: center;
    width: 100%;
    display: flex;
}

.ExpenseGroups-thead-tr-expand {
    width: calc(8vw - 10px);
}

.ExpenseGroups-tbody-td-expand {
    width: 8vw;
}

.ExpenseGroups-thead-tr-expand,
.ExpenseGroups-tbody-td-expand {
    float: left;
    text-align: right;
}

.ExpenseGroups-thead-tr-name,
.ExpenseGroups-thead-tr-state,
.ExpenseGroups-thead-tr-people,
.ExpenseGroups-thead-tr-expand {
    background-color: #2f55bd;
    padding-top: 5px;
    padding-bottom: 5px;
}


/*.ExpenseGroups-tbody-td-name,*/
.ExpenseGroups-tbody-td-state,
.ExpenseGroups-tbody-td-people,
.ExpenseGroups-tbody-td-expand {
    padding-top: 5px;
    padding-bottom: 5px;
}


.ExpenseGroups-button-expand {
    border: none;
    background-color: #4363c2;
    cursor: pointer;
    border-radius: 40px;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    margin-top: 5px;
    font-size: 150%;
}

/*This is the expandable row*/
.ExpenseGroups-tbody-tr-expand {
    background-color: #424242;
    color: black;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.option {
    background-color: grey;
    color: white;
}

.ExpenseGroups-UsersList-Container {
    /*display: flex;*/
}

.ExpenseGroups-UsersList-Content {
    float: left;
}

.ExpenseGroups-UsersList-Content:last-child {
    flex-grow: 1;
}

.ExpenseGroups-tbody-tr-main > div {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.ExpenseGroups-ClosedExclusionHeader {
    float:left;
    width: 0;
    padding-bottom: 10px;
}
.ExpenseGroups-ClosedExclusionHeader > div {
    width: 10vw;
    float: left
}
.ExpenseGroups-PaginationHeader {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.ExpenseGroups-Header  {
    width: 100%;
}

.ExpenseGroupsPage-PureModal-Backdrop{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: rgba(0,0,0,.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ExpenseGroupsPage-PureModal-Backdrop > div {
    background-color: #424242;
    border-radius: 10px;
    border-color: black;
    border-width: 2px;
    border-style: solid;
    padding: 5px;
    color: white;
}

.panel-heading {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    border-bottom: 1px;
    border-bottom-style: solid;
    border-color: black;
    font-size: 110%;
}

.ExpenseGroupsPage-PureModal-Footer > div {
    float: right;
    padding-left: 10px;
}

.close {
    display: none;
}

.ExpenseGroupsPage-PureModal-Content {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 110%;
}