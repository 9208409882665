.AddTransaction-Wrapper {
    margin-right: 5vw;
    margin-left: 5vw;
}

.AddTransaction-Confirm,
.AddTransaction-Amount,
.AddTransaction-Details,
.AddTransaction-Date,
.AddTransaction-UserWhoIsPaidFor > div,
.AddTransaction-UserWhoPaid > div {
    padding-top: 5px;
    margin-top: 5px;
}

.AddTransaction-Amount > input,
.AddTransaction-Details > input,
.react-datepicker__input-container > input {
    border-radius: 5px;
    /*border: none;*/
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    width: 100%;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    color: rgb(170, 170, 170);
;
}


.multiselect-container:hover,
.AddTransaction-Amount > input:hover,
.AddTransaction-Details > input:hover,
.react-datepicker__input-container > input:hover {
    background-color: #5e5e5e;
}
